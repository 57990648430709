export enum COOKIE_KEYS {
  AC_TOKEN = 'ac-auth.id-token',
  AC_REFRESH_TOKEN = 'ac-auth.refresh-token',
  TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  LEGACY_TOKEN = 'legacy_token',
  SAML_LOGOUT_URL = 'saml_logout_url',
  IS_CONNECTED_AS = 'is_connected_as',
  IMPERSONATE_TOKEN = 'impersonate_token',
  AUTH_STRATEGY = 'auth_strategy',
  HIDE_INTEGRATION_UPDATE_MODAL = 'hide_integration_update_modal',
}
