import { AIRCALL_AUTH_API_ENDPOINTS } from '../constants/endpoints.constants';
import {
  deleteResource,
  getResource,
  postNormalizedResource,
  postResource,
} from '../helpers/http.helpers';

import {
  CognitoMFATokenResponse,
  CognitoTokenResponse,
  ConfirmInvitationPayload,
  ConfirmInvitationResponse,
} from './auth.decl';

import { doRequest as httpRequest } from '@dashboard/library';
import { LoginFormValues } from '@pages/login/PasswordLoginSection/PasswordLoginForm.container';
import { MFAFormValues, MfaErrorResponse } from '@state/app/authentication/authentication.decl';

export function fetchSsoIdpCallback(email: string): Promise<{ url: string; logoutUrl: string }> {
  return postResource<{ url: string; logoutUrl: string }>(
    AIRCALL_AUTH_API_ENDPOINTS.SAML_IDP,
    { email },
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aircall-Platform': 'dashboard-v4',
    }
  );
}

export async function sessionFromCredentials(
  credentials: LoginFormValues
): Promise<CognitoTokenResponse | null> {
  return postNormalizedResource<CognitoTokenResponse>(
    AIRCALL_AUTH_API_ENDPOINTS.USERS_SIGNIN,
    credentials,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aircall-Platform': 'dashboard-v4',
    }
  );
}

export async function sessionFromCredentialsForMFA(
  credentials: LoginFormValues
): Promise<CognitoMFATokenResponse | null> {
  const requestCredentials: RequestCredentials = 'include';
  return postNormalizedResource<CognitoMFATokenResponse>(
    AIRCALL_AUTH_API_ENDPOINTS.USERS_SIGNIN_MFA,
    credentials,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aircall-Platform': 'dashboard-v4',
    },
    requestCredentials
  );
}

export async function sessionFromMFACode(
  credentials: MFAFormValues
): Promise<CognitoTokenResponse | MfaErrorResponse> {
  return httpRequest<CognitoTokenResponse>(AIRCALL_AUTH_API_ENDPOINTS.USERS_SIGNIN_VALIDATE_CODE, {
    method: 'POST',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aircall-Platform': 'dashboard-v4',
    },
    body: JSON.stringify({ ...credentials }),
  });
}

export async function resendMFACode(
  email: string
): Promise<CognitoTokenResponse | MfaErrorResponse> {
  return httpRequest<CognitoTokenResponse>(AIRCALL_AUTH_API_ENDPOINTS.USERS_SIGNIN_RESEND_CODE, {
    method: 'POST',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aircall-Platform': 'dashboard-v4',
    },
    body: JSON.stringify({ email }),
  });
}

export async function sessionFromGoogleToken(googleIdToken: string): Promise<CognitoTokenResponse> {
  return postResource<CognitoTokenResponse>(
    AIRCALL_AUTH_API_ENDPOINTS.GOOGLE_SIGNIN,
    { idToken: googleIdToken },
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aircall-Platform': 'dashboard-v4',
    }
  );
}

export async function sessionFromSsoCode(
  code: string,
  redirectUrl?: string
): Promise<CognitoTokenResponse> {
  return postResource<CognitoTokenResponse>(
    AIRCALL_AUTH_API_ENDPOINTS.SAML_CODE,
    {
      code,
      ...(redirectUrl && { redirectUrl }),
    },
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aircall-Platform': 'dashboard-v4',
    }
  );
}

export async function deleteCredentials(refreshToken: string): Promise<CognitoTokenResponse> {
  return deleteResource(
    AIRCALL_AUTH_API_ENDPOINTS.USERS_SIGNIN,
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Aircall-Platform': 'dashboard-v4',
    },
    {
      refreshToken,
    }
  );
}

export function sendConfirmInvitation({
  password,
  passwordConfirmation,
  email,
  invitationToken,
}: ConfirmInvitationPayload): Promise<ConfirmInvitationResponse | CognitoTokenResponse> {
  // We can't use postNormalizedResource here as we need to send camel cased keys
  return postResource<ConfirmInvitationResponse | CognitoTokenResponse>(
    AIRCALL_AUTH_API_ENDPOINTS.USERS_ACCEPT_INVITATION_V2,
    {
      // the existing dashboard passes fixed timezone and language
      password,
      passwordConfirmation,
      email,
      confirmationCode: invitationToken,
      timezone: 'UTC',
      lang: 'en-US',
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    { 'Aircall-Platform': 'dashboard-v4' }
  );
}

// refresh cognito token and return new `idToken`
export async function requestRefreshToken({
  isMigrated,
  token,
  refreshToken,
}: {
  isMigrated: boolean;
  token: string;
  refreshToken: string;
}): Promise<CognitoTokenResponse> {
  const url = isMigrated
    ? AIRCALL_AUTH_API_ENDPOINTS.REFRESH_TOKEN
    : AIRCALL_AUTH_API_ENDPOINTS.NON_MIGRATED_REFRESH_TOKEN;
  return getResource(url, {
    authorization: `Bearer ${refreshToken}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'x-id-token': token,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ...(isMigrated && { 'Aircall-Platform': 'dashboard-v4' }),
  });
}

export async function getCognitoTokenFromNotMigratedCompanies(
  token: string
): Promise<CognitoTokenResponse> {
  return getResource(AIRCALL_AUTH_API_ENDPOINTS.COGNITO_TOKEN, {
    authorization: `Bearer ${token}`,
  });
}
