import { useEffect } from 'react';

import { useContractCheckAndSign } from './hooks/useContractCheckAndSign';

import { Box, Flex, Spacer, ThemeProvider, Typography } from '@aircall/tractor-v2';
import { ReactComponent as AircallLogo } from '@assets/images/aircall_logo.svg';
import dashboardIntro from '@assets/images/dashboard_intro.png';
import phoneImage from '@assets/images/phone.png';
import { DESKTOP_DEVICE } from '@constants/responsive.contants';
import * as ROUTES from '@constants/routes.constants';
import { Loading, useGraphQuery } from '@dashboard/library';
import { GetSignupInformation } from '@generated/GetSignupInformation';
import { SIGNUP_INFORMATION_QUERY } from '@graphql/queries/SignupInformationQuery';
import { isProd } from '@helpers/env.helpers';
import { useCreateNumber } from '@hooks/useCreateNumber/useCreateNumber';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useOnboardingData } from '@hooks/useOnboardingData';
import { useToast } from '@hooks/useToast';
import { OnboardingCard, OnboardingCardProps } from '@pages/onboarding/components/OnboardingCard';
import styled from '@xstyled/styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AircallLogoWrapper = styled(Box)`
  @media (max-width: ${DESKTOP_DEVICE}) {
    display: block;
  }
`;

const GradientLayout = styled(Flex)`
  background: linear-gradient(60deg, rgb(252 187 38 / 0.2), transparent 30%),
    linear-gradient(to left top, rgb(0 179 136 / 0.2), transparent 25%);
  overflow-y: auto;
`;

const ContentWrapper = styled(Flex)`
  position: relative;
  max-width: 730px;
`;

export function LandingPage() {
  const { t } = useTranslation();
  const { daysLeftInTrial } = useContractCheckAndSign();
  const { showOnboardingTrial } = useFeatures();
  const navigate = useNavigate();
  const { data } = useGraphQuery<GetSignupInformation>(SIGNUP_INFORMATION_QUERY, {
    skip: !showOnboardingTrial,
  });
  const { createOnboardingData } = useOnboardingData();
  const { showToast } = useToast();

  const { createNumber, createNumberLoading } = useCreateNumber(data?.getSignupInformation);

  useEffect(() => {
    if (!showOnboardingTrial) {
      navigate('/', { replace: true });
    } else {
      createNumber().catch(() => {
        showToast({
          variant: 'critical',
          message: t('login_intro.create_number_error'),
          dismissIn: 0,
        });
      });
    }
  }, [createNumber, navigate, showOnboardingTrial, showToast, t]);

  useEffect(() => {
    createOnboardingData();
  }, [createOnboardingData]);

  if (!showOnboardingTrial) {
    return <Loading data-test='landing-page-loader' />;
  }

  const buttonUrl = isProd()
    ? t('login_intro.phone_card.button_link')
    : t('login_intro.phone_card.non_prod_button_link');

  const phoneAppCardProps: OnboardingCardProps = {
    heading: t('login_intro.phone_card.title'),
    subHeading: t('login_intro.phone_card.subtitle'),
    image: phoneImage,
    button: {
      label: t('login_intro.phone_card.button_label'),
      onClick: () => window.open(buttonUrl),
    },
    loading: createNumberLoading,
  };

  const dashboardCardProps: OnboardingCardProps = {
    heading: t('login_intro.dashboard_card.title'),
    subHeading: t('login_intro.dashboard_card.subtitle'),
    image: dashboardIntro,
    button: {
      label: t('login_intro.dashboard_card.button_label'),
      onClick: () => navigate(ROUTES.ONBOARDING_ROUTE, { replace: true }),
    },
    loading: createNumberLoading,
  };

  return (
    <ThemeProvider>
      <GradientLayout w='100%' h='100%' p='m' data-test='landing-page-wrapper'>
        <ContentWrapper
          m='auto'
          data-test='content-wrapper'
          flexDirection='column'
          alignItems='center'
        >
          <Spacer direction='vertical' space='xs' fluid alignItems='center'>
            <AircallLogoWrapper>
              <AircallLogo height='48px' width='48px' />
            </AircallLogoWrapper>
            <Typography variant='headingBoldM' textAlign='center' data-test='landing-page-heading'>
              <Trans i18nKey='login_intro.title' />
            </Typography>
            <Typography
              variant='bodyMediumM'
              textAlign='center'
              color='text-secondary'
              data-test='trial-days-left'
            >
              <Trans
                i18nKey='login_intro.subtitle'
                values={{
                  count: daysLeftInTrial,
                }}
              />
            </Typography>
          </Spacer>
          <Flex
            flexDirection={{
              xl: 'row',
              lg: 'row',
              md: 'row',
              _: 'column',
            }}
            w='100%'
            mt='40px'
            justifyContent='space-between'
            gap='30px'
            alignItems='center'
          >
            <OnboardingCard {...phoneAppCardProps} />
            <OnboardingCard {...dashboardCardProps} />
          </Flex>
        </ContentWrapper>
      </GradientLayout>
    </ThemeProvider>
  );
}
